<template>
  <div class="seminar-list">
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex lg12>
          <Toolbar :handle-add="createSeminarHandler" :enable-refresh="true" :handle-refresh="refreshData" :title="$t('seminars')" />
          <DataFilter :handle-filter="onSendFilter" :handle-reset="resetFilter">
            <SeminarFilterForm :values="filters" ref="filterForm" slot="filter" />
          </DataFilter>
          <br />
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page.sync="options.itemsPerPage"
            :loading="isLoading"
            :loading-text="$t('Loading...')"
            :options.sync="options"
            :server-items-length="totalItems"
            @update:options="onUpdateOptions"
            class="elevation-1"
            item-key="@id"
            v-model="selected"
          >
            <template v-slot:item.type="{ item }">
              <v-tooltip bottom v-if="item.presenceSeminar">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on">mdi-human-male-board</v-icon>
                </template>
                <span>Präsenzseminar</span>
              </v-tooltip>
              <v-tooltip bottom v-else-if="item.noOnline === false">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on">mdi-monitor</v-icon>
                </template>
                <span>Online-Seminar</span>
              </v-tooltip>
              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on">mdi-office-building</v-icon>
                </template>
                <span>InHouse Seminar</span>
              </v-tooltip>
            </template>
            <template v-slot:item.topics="{ item }">
              <v-chip v-for="topic in item.topics" :key="topic['topic']" :class="[(item.topics.length > 1) && 'mb-1 mr-1']" small outlined>
                {{ getTopicItem(topic, 'name') }}
              </v-chip>
            </template>
            <template v-slot:item.customer="{ item }">
              <template v-if="item.offer && getOfferItem(item.offer['@id'], 'customer')">
                <v-btn plain color="primary dark"
                       @click="openCustomerDialog(resolveCustomer(getOfferItem(item.offer['@id'], 'customer')))">
                  {{ getCustomerItem(getOfferItem(item.offer['@id'], 'customer'), 'customerNumber') }}
                </v-btn>
              </template>
            </template>
            <template v-slot:item.firstSeminarModuleStartDate="{ item }">
              {{ formatDateTime(item.firstSeminarModuleStartDate) }}
            </template>
            <template v-slot:item.participants="{ item }">
              {{ item.participantCount }}
            </template>
            <template v-slot:item.autoInvoiceStatus="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="mr-2" small>
                    <template v-if="item.seminarConfig.enabled === false || item.seminarConfig.autoInvoice === false">mdi-currency-usd-off</template>
                    <template v-else-if="item.seminarConfig.enabled === true && item.seminarConfig.autoInvoice === true">mdi-currency-usd</template>
                  </v-icon>
                </template>
                <span>
                  <template v-if="item.seminarConfig.enabled === false || item.seminarConfig.autoInvoice === false">Deaktiviert</template>
                  <template v-else-if="item.seminarConfig.enabled === true && item.seminarConfig.autoInvoice === true">Aktiviert</template>
                </span>
              </v-tooltip>
            </template>
            <template v-slot:item.status="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="mr-2" small>
                    <template v-if="item.seminarConfig.enabled === false">mdi-decagram-outline</template>
                    <template v-else-if="item.seminarConfig.enabled === true && item.seminarConfig.confirmed === false && item.seminarConfig.cancelled === false">mdi-decagram</template>
                    <template v-else-if="item.seminarConfig.confirmed && item.seminarConfig.completed === false">mdi-check-decagram</template>
                    <template v-else-if="item.seminarConfig.cancelled">mdi-close-circle</template>
                    <template v-else-if="item.seminarConfig.completed">mdi-flag-checkered</template>
                  </v-icon>
                </template>
                <span>
                  <template v-if="item.seminarConfig.enabled === false">Deaktiviert</template>
                  <template v-else-if="item.seminarConfig.enabled === true && item.seminarConfig.confirmed === false && item.seminarConfig.cancelled === false">Aktiviert</template>
                  <template v-else-if="item.seminarConfig.confirmed && item.seminarConfig.completed === false">Bestätigt</template>
                  <template v-else-if="item.seminarConfig.cancelled">Storniert</template>
                  <template v-else-if="item.seminarConfig.completed">Abgeschlossen</template>
                </span>
              </v-tooltip>
            </template>
            <template v-slot:item.profitLoss="{ item }">
              <v-chip small :color="(Math.round(item.totalEarnings - item.totalExpenses)) > 0 ? 'green' : 'red'" class="ml-2">
                {{ (Math.round((item.totalEarnings - item.totalExpenses) * 100) / 100) }} &euro;
              </v-chip>
            </template>
            <template v-slot:item.action="props">
              <ActionCell
                :handle-delete="() => deleteHandler(props.item)"
                :handle-show="() => showHandler(props.item)"
                :enable-delete="isDeleteEnabled(props.item)"
                :enable-edit="false"
              />
            </template>
          </v-data-table>
          <SeminarCombinedDialog
            v-model="showSeminarDialogForm"
            :seminar-item="seminarFormItem"
            :seminar-module-item="seminarModuleFormItem"
            :allow-name="isInhouse"
            :handle-created="handleCreated"
            :handle-updated="handleUpdated"
            :show-handle="showSeminarDialogForm"
            :title="seminarDialogTitle"
            @close="seminarDialogControl(true)"
          />
          <CustomerInfoDialog
            v-model="showCustomerDialog"
            :customer="customerDialogItem"
            :key="customerDialogItem.id"
            @close="showCustomerDialog = false"
          />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import remove from 'lodash/remove';
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import ListMixin from '../../mixins/ListMixin';
import SeminarFilterForm from '../../components/seminar/Filter';
import DataFilter from '../../components/DataFilter';
import Toolbar from '../../components/Toolbar';
import ActionCell from '../../components/ActionCell';
import TopicMixin from "@/mixins/TopicMixin";
import TenantMixin from "@/mixins/TenantMixin";
import OfferMixin from "@/mixins/OfferMixin";
import CustomerMixin from "@/mixins/CustomerMixin";
import CustomerInfoDialog from "@/components/customer/CustomerInfoDialog";
import SeminarCombinedDialog from "@/components/seminarCombined/Dialog";
import { formatDateTime } from '@/utils/dates';

export default {
  name: 'SeminarList',
  servicePrefix: 'Seminar',
  mixins: [ListMixin, TopicMixin, TenantMixin, OfferMixin, CustomerMixin],
  components: {
    SeminarCombinedDialog,
    CustomerInfoDialog,
    SeminarFilterForm,
    DataFilter,
    Toolbar,
    ActionCell,
  },
  data() {
    return {
      options: {
        sortBy: ['timeSlot.start'],
        sortDesc: [false]
      },
      selected: [],
      showSeminarDialogForm: false,
      seminarFormItem: null,
      seminarModuleFormItem: null,
      seminarDialogTitle: this.$t('AddSeminar'),
      showCustomerDialog: false,
      customerDialogItem: {},
      filters: {
        __Fdate: {
          property: 'timeSlot.start',
          when: 'after',
          date: formatDateTime(this.yesterday(), 'DD.MM.YYYY')
        }
      },
    };
  },
  computed: {
    ...mapGetters('seminar', {
      items: 'list',
    }),
    ...mapFields('seminar', {
      deletedItem: 'deleted',
      error: 'error',
      isLoading: 'isLoading',
      resetList: 'resetList',
      totalItems: 'totalItems',
      view: 'view',
    }),
    headers() {
      const nameColumn = { text: this.$i18n.t('name'), value: 'name' };
      const customerColumn = { text: this.$i18n.t('Customer'), value: 'customer', sortable: false, align: 'center' };

      let headerItems = [
        { text: this.$i18n.t('type'), value: 'type', align: 'center', sortable: false },
      ];
      if (this.isInhouse) {
        headerItems.push(nameColumn);
        headerItems.push(customerColumn)
      }
      headerItems.push({ text: this.$i18n.t('Number'), value: 'number' });
      headerItems.push({ text: this.$i18n.t('Topics'), value: 'topics', sortable: false });
      headerItems.push({
        text: this.$i18n.t('firstSeminarModuleStartDate'),
        value: 'firstSeminarModuleStartDate',
        align: 'center',
      });
      headerItems.push({ text: this.$i18n.t('Participants'), value: 'participants', sortable: false, align: 'center' });
      headerItems.push({ text: this.$i18n.t('status'), value: 'status', sortable: false, align: 'center' });
      headerItems.push({ text: this.$i18n.t('autoInvoiceStatus'), value: 'autoInvoiceStatus', sortable: false, align: 'center' });
      headerItems.push({ text: this.$i18n.t('profitLoss'), value: 'profitLoss', sortable: false, align: 'center' });
      headerItems.push({
        text: this.$i18n.t('Actions'),
        value: 'action',
        sortable: false,
        align: 'end',
      });

      return headerItems;
    },
  },
  methods: {
    log(message) {
      console.log(message);
    },
     yesterday() {
       var d = new Date();
      d = d.setDate(d.getDate() - 1);
      return d;
    },
    ...mapActions('seminar', {
      getPage: 'fetchAll',
      deleteItem: 'del',
      clearSeminarData: 'resetAllData',
    }),
    seminarDialogControl(close = false) {
      this.showSeminarDialogForm = !close;
    },
    createSeminarHandler() {
      this.seminarFormItem = null;
      this.seminarModuleFormItem = null;
      this.seminarDialogTitle = this.$t('AddSeminar');
      this.seminarDialogControl();
    },
    handleCreated(item) {
      this.$router.push({
        name: `${this.$options.servicePrefix}Show`,
        params: { id: item['@id'] },
      });
    },
    handleUpdated(item) {
      console.log(item);
    },
    isDeleteEnabled(item) {
      return this.hasRole('ROLE_DELETE_SEMINAR') && false === this.allModulesCompleted(item);
    },
    isEditEnabled(item) {
      return this.hasRole('ROLE_UPDATE_SEMINAR') && false === this.allModulesCompleted(item);
    },
    allModulesCompleted(item) {
      return item.seminarConfig.completed;
    },
    openCustomerDialog(customer) {
      this.customerDialogItem = customer;
      this.showCustomerDialog = true;
    },
    refreshData(){
      this.clearSeminarData();
      this.onSendFilter();
    }
  },
  created() {
    if (false === this.isInhouse) {
      remove(this.headers, (item) => item.value === 'customer');
    }
  },
  mounted() {
    if (false === this.isInhouse) {
      remove(this.headers, (item) => item.value === 'customer');
    }
  },
};
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" :sm="columnCount">
        <v-text-field :label="$t('name')" type="text" v-model="item.name" />
      </v-col>
      <v-col cols="12" :sm="columnCount">
        <v-text-field :label="$t('number')" type="text" v-model="item.number" />
      </v-col>
      <v-col cols="12" :sm="columnCount" v-if="isInhouse">
        <v-text-field :label="$t('customerNumber')" type="text" v-model="item.offer.customer.customerNumber" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" :sm="columnCount">
        <v-checkbox :label="$t('isPresenceSeminar')" v-model="item.__Fis_presenceSeminar_1"
          @change="uncheck(item, 'is')" />
      </v-col>
      <v-col cols="12" :sm="columnCount">
        <v-checkbox :label="$t('isNotPresenceSeminar')" v-model="item.__Fis_presenceSeminar_0"
          @change="uncheck(item, 'isNot')" />
      </v-col>
      <v-col cols="1" :sm="columnCount">
        <v-text-field :label="$t('showSeminarsAfter')" type="text" v-model="item.__Fdate.date" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import TenantMixin from "@/mixins/TenantMixin";

export default {
  name: 'SeminarFilter',
  mixins: [TenantMixin],
  props: {
    values: {
      type: Object,
      required: true
    }
  },
  computed: {
    item() {
      return this.initialValues || this.values;
    },
    columnCount() {
      return (this.isInhouse) ? 4 : 6;
    }
  },
  methods: {
    uncheck(item, el) {
      if (item.__Fis_presenceSeminar_1 === true && el === 'is') {
        item.__Fis_presenceSeminar_0 = false
      }
      if (item.__Fis_presenceSeminar_0 === true && el === 'isNot') {
        item.__Fis_presenceSeminar_1 = false
      }
    }
  }
};
</script>
